<template>
    <div class="users-list">
        <div class="row">
            <div class="col-12">
                <h3>Логи кошельков</h3>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="form-row align-items-center">
                    <div class="col-sm-3 my-1">
                        <label class="sr-only" for="inlineFormInputGroupUsername">Поиск</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text">Поиск</div>
                            </div>
                            <input type="text" v-model="search" class="form-control"
                                   id="inlineFormInputGroupUsername" placeholder="Поиск">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <table id="users-list-table" class="table users-list-table">
                    <tr>
                        <th>ID кошелька</th>
                        <th>Пользователь</th>
                        <th class="text-left">До</th>
                        <th>После</th>
                        <th>Разница</th>
                        <th>Описание</th>
                        <th>Время</th>
                    </tr>
                    <tr v-for="item in items" v-bind:key="item.id">
                        <td>{{item.purse.id}}</td>
                        <td>{{item.purse.user.username}}</td>
                        <td>{{item.before}}</td>
                        <td>{{item.after}}</td>
                        <td>{{item.diff}}</td>
                        <td>{{item.description}}</td>
                        <td>{{ new Date(item.createdAt) | moment("YYYY-MM-DD HH:mm") }}</td>
                    </tr>
                </table>
                <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        v-on:change="selectPage"
                        aria-controls="users-list"
                ></b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import PurseService from "../services/PurseService";
    export default {
        name: "PursesLogsList",
        data: function(){
            return {
                currentPage: 1,
                totalRows: 0,
                perPage:1,
                items: [],
                search: ""
            }
        },
        methods: {
            selectPage(page) {
                this.currentPage=page;
                this.loadData()
            },
            loadData(){
                var self = this;
                PurseService.getAllLogs(self.currentPage-1, self.search)
                    .then((response) => {
                        self.items = response.data.content;

                        self.currentPage = response.data.number+1;
                        self.totalRows = response.data.totalElements;
                        self.perPage = response.data.size;
                    })
            }
        },

        watch: {
            search: function (val) {
                if (val == '' || val.length >=3) {
                    this.loadData();
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

